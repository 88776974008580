import styled from "styled-components";
import DownArrow from "assets/images/down-arrow.svg";

export const CoachingGlanceContainer = styled.div`
  .cost-calculation {
    background-color: #f0f4f3;
  }

  #auswahl {
    position: relative;
    background-color: rgb(28, 157, 135);
    width: fit-content;
    padding: 20px;
    color: white;
    border-radius: 1rem;
    margin-top: 4rem;
  }

  #auswahl::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent rgb(28, 157, 135) transparent;
  }
`;

export const SubscriptionProDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  select {
    background-color: #fff;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 12px 30px 12px 16px;
    color: #44554c;
    font-size: 16px;
    line-height: 22px;
    background-image: url("${DownArrow}");
    background-repeat: no-repeat;
    background-position: center right 0.3125rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    max-width: 424px;
    width: 100%;
    margin: 0 auto;
    font-family: "RubikRegular";
  }
`;
